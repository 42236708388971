import React from "react";

function Home() {
  return (
    <>
      {/*  <!-- Carousel Start --> */}

      <div class="container-fluid px-0">
        <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
          <ol class="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="First slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="1"
              aria-label="Second slide"
            ></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <img
                src="img/carousel-1.jpg"
                class="img-fluid" /* 
                style={{ maxHeight: "80vh", width: "100%", objectFit: "cover" }} */
                alt="First slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-secondary h4 animated fadeInUp">
                    Best IT Solutions
                  </h6>
                  <h1 class="text-white display-1 mb-4 animated fadeInRight">
                    An Innovative IT Solutions Agency
                  </h1>
                  {/* <p class="mb-4 text-white fs-5 animated fadeInDown">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum. Pellentesque aliquam dolor eget urna ultricies
                    tincidunt.
                  </p>
                  <a href="" class="me-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" class="ms-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img
                src="img/carousel-2.jpg"
                class="img-fluid" /* style={{ maxHeight: "80vh", width: "100%", objectFit: "cover", filter: "none" }} */
                alt="Second slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-secondary h4 animated fadeInUp">
                    Best IT Solutions
                  </h6>
                  <h1 class="text-white display-1 mb-4 animated fadeInLeft">
                    Quality Digital Services You Really Need!
                  </h1>
                  {/* <p class="mb-4 text-white fs-5 animated fadeInDown">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt.</p>
                                 <a href="" class="me-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" class="ms-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/*  <!-- Carousel End --> */}

      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: "25%" }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: "25%", left: "25%" }}
                >
                  <img
                    src="img/about-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">About Us</h5>
              <h1 className="mb-4">
                About HighTech Agency And Its Innovative IT Solutions
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                efficitur quis purus ut interdum. Pellentesque aliquam dolor
                eget urna ultricies tincidunt. Nam volutpat libero sit amet leo
                cursus, ac viverra eros tristique. Morbi quis quam mi. Cras vel
                gravida eros. Proin scelerisque quam nec elementum viverra.
                Suspendisse viverra hendrerit diam in tempus. Etiam gravida
                justo nec erat vestibulum, et malesuada augue laoreet.
              </p>
              <p className="mb-4">
                Pellentesque aliquam dolor eget urna ultricies tincidunt. Nam
                volutpat libero sit amet leo cursus, ac viverra eros tristique.
                Morbi quis quam mi. Cras vel gravida eros. Proin scelerisque
                quam nec elementum viverra. Suspendisse viverra hendrerit diam
                in tempus.
              </p>
              <a
                href="#"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-code fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Web Design</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-file-code fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Web Development</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">UI/UX Design</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-user-secret fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Web Security</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Digital Marketing</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-laptop fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Programming</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Project</h5>
            <h1>Our Recently Completed Projects</h1>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Web Design</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Cyber Security</h4>
                      <p className="m-0 text-white">Cyber Security Core</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-3.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Mobile Info</h4>
                      <p className="m-0 text-white">Upcoming Phone</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-4.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Web Development</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-5.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Digital Marketing</h4>
                      <p className="m-0 text-white">Marketing Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-6.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Keyword Research</h4>
                      <p className="m-0 text-white">Keyword Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*   <!-- Team Start --> */}
      <div class="container-fluid py-5 mb-5 team">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>
          <div
            class="owl-carousel team-carousel wow fadeIn"
            data-wow-delay=".5s"
          >
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-1.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-2.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-3.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-4.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <!-- Team End --> */}

      {/* 
        <!-- Testimonial Start --> */}
      <div class="container-fluid testimonial py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Testimonial</h5>
            <h1>Our Client Saying!</h1>
          </div>
          <div
            class="owl-carousel testimonial-carousel wow fadeIn"
            data-wow-delay=".5s"
          >
            <div class="testimonial-item border p-4">
              <div class="d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-1.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-2.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-3.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-4.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
        <!-- Testimonial End --> */}
      


      

      {/*  <!-- Contact Start --> */}
      <div class="container-fluid py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{maxWidth: "600px" }}
          >
            <h5 class="text-primary">Get In Touch</h5>
            <h1 class="mb-3">Contact for any query</h1>
            <p class="mb-2">
              The contact form is currently inactive. Get a functional and
              working contact form with Ajax & PHP in a few minutes. Just copy
              and paste the files, add a little code and you're done.{" "}
              <a href="https://htmlcodex.com/contact-form">Download Now</a>.
            </p>
          </div>
          <div class="contact-detail position-relative p-5">
            <div class="row g-5 mb-5 justify-content-center">
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{width:"64px", height: "64px" }}
                  >
                    <i class="fas fa-map-marker-alt text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Address</h4>
                    <a
                      href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6"
                      target="_blank"
                      class="h5"
                    >
                      23 rank Str, NY
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{width:"64px", height: "64px" }}
                  >
                    <i class="fa fa-phone text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Call Us</h4>
                    <a class="h5" href="tel:+0123456789" target="_blank">
                      +012 3456 7890
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{width: "64px", height: "64px" }}
                  >
                    <i class="fa fa-envelope text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Email Us</h4>
                    <a
                      class="h5"
                      href="mailto:info@example.com"
                      target="_blank"
                    >
                      info@example.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-5">
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="p-5 h-100 rounded contact-map">
                  <iframe
                    class="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.4710403339755!2d-73.82241512404069!3d40.685622471397615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26749046ee14f%3A0xea672968476d962c!2s123rd%20St%2C%20Queens%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1686493221834!5m2!1sen!2sbd"
                    style={{ border:"0"}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="p-5 rounded contact-form">
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="email"
                      class="form-control border-0 py-3"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Project"
                    />
                  </div>
                  <div class="mb-4">
                    <textarea
                      class="w-100 form-control border-0 py-3"
                      rows="6"
                      cols="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="text-start">
                    <button
                      class="btn bg-primary text-white py-3 px-5"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
        <!-- Contact End -->
 */}
    </>
  );
}

export default Home;
