import React from "react";

function Services() {
  return (
    <section id="services">
      <h2>Our Services</h2>
      <ul>
        <li>Web Development</li>
        <li>Mobile App Development</li>
        <li>Cloud Solutions</li>
        <li>Consulting</li>
      </ul>
    </section>
  );
}

export default Services;
