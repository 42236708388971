/* global $ */

import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services";
// import Contact from './components/Contact';
import "./App.css";

function App() {
  useEffect(() => {
    const loadScripts = async () => {
      const scripts = [
        "https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js",
        "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js",
        "lib/wow/wow.min.js",
        "lib/easing/easing.min.js",
        "lib/waypoints/waypoints.min.js",
        "lib/owlcarousel/owl.carousel.min.js",
        "js/main.js",
      ];

      for (const src of scripts) {
        await new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.async = true;
          script.onload = resolve;
          document.body.appendChild(script);
        });
      }

      // Initialize Owl Carousel after all scripts are loaded
      if (window.jQuery) {
        $(document).ready(function () {
          $(".owl-carousel").owlCarousel({
            items: 1,
            loop: true,
            margin: 10,
            nav: true,
          });
        });
      }
    };

    loadScripts();
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
