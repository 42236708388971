import React from "react";

function About() {
    return (
      <React.Fragment>
        <section id="about">
          <h2>About Us</h2>
          <p>
            We are a team of experienced professionals delivering quality IT
            solutions.We are a team of experienced professionals delivering
            quality IT solutions.We are a team of experienced professionals
            delivering quality IT solutions.We are a team of experienced
            professionals delivering quality IT solutions.
          </p>
        </section>
      </React.Fragment>
    );
}

export default About;
